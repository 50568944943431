import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/nulogy.design/nulogy.design/src/components/MarkdownRenderer.js";
import { ApplicationFrame, BrandedNavBar, Text, Link, Breadcrumbs, Page } from "@nulogy/components";
import { config } from "../../playgrounds/application-frame";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Example = makeShortcode("Example");
const PropsTable = makeShortcode("PropsTable");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Application Frame`}</h1>
    <p>{`There are three components we use to build up Nulogy's standard layout: An ApplicationFrame, a Page, and an optional Sidebar.`}</p>
    <Example componentName="applicationFrame" scope={{
      ApplicationFrame,
      BrandedNavBar,
      Text,
      Link,
      Breadcrumbs,
      Link,
      Page
    }} example="ApplicationFrame" mdxType="Example" />
    <h2>{`Props`}</h2>
    <PropsTable propsConfig={config.props} mdxType="PropsTable" />
    <p>{`The Application Frame component has access to all style props. See the `}<inlineCode parentName="p">{`style`}</inlineCode>{` prop documentation for a full list of available props.`}</p>
    <h2>{`Related Components`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/page"
        }}>{`Page`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/sidebar"
        }}>{`Sidebar`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      